import React from 'react'
import { Video } from '../Video'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import { overviewPaddingX } from './OverviewBlock'

export function OverviewVideoBlock() {
  return (
    <>
      <Box backgroundColor="darkPurple">
        <Block maxWidth="largeBlock" paddingX={overviewPaddingX}>
          <Box>
            <Box
              isolation="isolate"
              overflow="hidden"
              display="inline-flex"
              boxShadow="strong"
              borderRadius={{ xs: 8, sm: 16, md: 24 }}
            >
              <Box display={{ xs: 'none', md: 'block' }}>
                <Video name="home-video" controls={false} />
              </Box>
              <Box display={{ xs: 'block', md: 'none' }}>
                <Video
                  posterName="home-video"
                  name="home-video-mobile"
                  controls={false}
                />
              </Box>
            </Box>
          </Box>
        </Block>
      </Box>
      <Box
        backgroundColor="white"
        height={92}
        css={`
          margin-top: -64px;
        `}
      />
    </>
  )
}
