import React from 'react'
import Box from '../primitives/Box'

export const DarkAnnouncementBadge = ({ text, href }: { text; href }) => {
  return (
    <Box.a
      css={`
        background: linear-gradient(
          116deg,
          rgba(92, 89, 159, 0.5) 0%,
          rgba(118, 57, 128, 0.5) 100%
        );

        &:hover {
          color: #fff;
          transform: none !important;
        }
      `}
      font="buttonLabel"
      fontSize={16}
      lineHeight="20px"
      color="white"
      href={href}
      borderRadius={9999}
      display="inline-flex"
      paddingX={16}
      paddingY={8}
      gap={12}
      alignItems="center"
      target="_self"
    >
      <Box
        width={7}
        height={7}
        backgroundColor="illustrationPink"
        borderRadius={9999}
        display={{ xs: 'none', sm: 'block' }}
      />
      <span>{text}</span>
    </Box.a>
  )
}
